<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import moment from 'moment';
import Breadcrumb from '../../components/documents/patchName.vue';
export default {
  name: 'AllDocuments',
  components: { Breadcrumb },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      Documents: [],
      loading: false,
      sentPage: 1,
      optionspag: [],
      componenteHeight: '93vh',
      SearchStatus: [{ id: 0, name: 'Selecione' }, { id: 1, name: 'Em movimentação' }, { id: 2, name: 'Arquivado' }, { id: 3, name: 'Excluidos' }],
      date_start: '',
      date_end: '',
      SearchStatusSelect: 0,
      search: '',
      progress: 0,
      SignerSucess: false,
      NameDocSigner: [],
      showPassword: false,
      wrongPasswords: false
    };
  },
  watch: {
    date_start: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '') {
        this.Searchs();
      }
      if (this.date_start !== '' && this.date_end === '') {
        this.Searchs();
      }
      if (this.date_start === '' && this.date_end !== '') {
        this.Searchs();
      }
    },
    date_end: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '') {
        this.Searchs();
      }
      if (this.date_start !== '' && this.date_end === '') {
        this.Searchs();
      }
      if (this.date_start === '' && this.date_end !== '') {
        this.Searchs();
      }
    },
    SearchStatusSelect: function (newValue) {
      this.Searchs();
    }
  },
  mounted () {
    this.Get_docs();
    this.checkComponentHeight();
    this.Store.DepartamentosAll();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Send');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MMMM/YYYY');
    },
    StatusSigner (value) {
      const singers = value.some(signer => signer.user_id === this.Store.user.id);
      if (singers) {
        value.forEach(signerId => {
          if (signerId.user_id === this.Store.user.id) {
            if (signerId.clicksign_status === '0') {
              return 0;
            }
            if (signerId.clicksign_status !== '0') {
              return 1;
            }
          }
        });
      }
    },
    Searchs () {
      this.Get_docs();
    },
    Get_docs () {
      this.loading = true;
      this.Documents = [];
      this.DocumentsShared = [];
      const date = {
        page: this.sentPage
      };
      let valuesDate = {};
      let valuesStatus = {};
      let valuesSearch = {};
      let params = {};
      if (this.date_start !== '' && this.date_end !== '') {
        valuesDate = { date_start: this.date_start, date_end: this.date_end };
      }

      if (this.SearchStatusSelect !== 0) {
        if (this.SearchStatusSelect === 1) {
          valuesStatus = { status: 'movement' };
        }
        if (this.SearchStatusSelect === 2) {
          valuesStatus = { status: 'muted' };
        }
        if (this.SearchStatusSelect === 3) {
          valuesStatus = { status: 'filed' };
        }
      }

      if (this.search !== '') {
        valuesSearch = { search: this.search };
      }
      params = { ...date, ...valuesDate, ...valuesStatus, ...valuesSearch };
      AxiosAPI
        .get('/api/documents', {
          params,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          this.optionspag = response.data.documents;
          this.Documents = response.data.documents.data;
          this.loading = false;
        });
    },

    onPageChange (event) {
      this.sentPage = event.page + 1;
      this.Get_docs();
    },
    star (data, value) {
      data.favorited = true;
      if (value === 1) {
        data.favorited = true;
        AxiosAPI
          .post('/api/documents/' + data.id + '/favorite', {}, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
            console.log(response);
          });
      }
      if (value === 2) {
        data.favorited = false;
        AxiosAPI
          .delete('/api/documents/' + data.id + '/favorite', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
            console.log(response);
          });
      }
    },
    async Signer () {
      document.getElementById('closeModalSends').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.NameDocSigner = resp.data.document;
            this.SignerSucess = true;
            this.Get_docs();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.wrongPassword.click();
          clearInterval(interval);
        });
    },
    SignerStart () {
      this.password = '';
      this.wrongPasswords = false;
    }
  }
};
</script>
  <style>
  </style>
<template>
    <div class='py-4 container-fluid' id="Send" :style="{ minHeight: componenteHeight }">
      <Breadcrumb />
      <button type="button" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>
      <h4 style="color:black">Todos os documentos</h4>
      <div v-if="SignerSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} assinado!  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerSucess = false" />
          </div>
        </div>
      <div class="background-pags">
      <div class="row d-flex justify-content-center pt-4">
      <div class="d-flex align-items-center justify-content-center col mb-2">
        <div class="">
          <label class="label-docs"><b>Buscar</b></label>
          <form class="form-inline">
            <div class="input-group" style="width:336px;">
              <input type="text" class="form-control" v-model="search" placeholder="Assunto ou protocolo" aria-label="Username" aria-describedby="basic-addon1">
              <div class="input-group-prepend">
                <span class="input-group-text background-Docs" id="basic-addon1"
                style="border-right-width: 1px;border-right-style: groove;border-left-width: 2px;border-top-right-radius: 5px;border-bottom-right-radius: 5px;cursor:pointer" @click="Searchs">
                <i class="fa fa-search text-white" aria-hidden="true"></i></span>
              </div>
            </div>
          </form>
          </div>
      </div>
      <div class="d-flex align-items-center justify-content-center col mb-2">
        <div class="">
          <label class="label-docs"><b>Périodo</b></label>
          <div class="d-flex">
            <input type="text" class="form-control" v-model="date_start" onfocus="(this.type='date')" placeholder="Data inicial" style="width: 168px;border-right: 0;border-bottom-right-radius: 0;border-top-right-radius: 0;"/>
          <input type="text" class="form-control" v-model="date_end" onfocus="(this.type='date')" placeholder="Data final" style="width: 168px;border-left: 0;border-bottom-left-radius: 0;border-top-left-radius: 0;"/>
          </div>
          </div>
      </div>
      <div class="d-flex align-items-center justify-content-center col mb-2">
        <div class="">
          <label class="label-docs"><b>Situação</b></label>
          <select id='choices-category' v-model="SearchStatusSelect" class='my_form' name='choices-category' style="width:336px;">
              <option v-for='option in SearchStatus' :key='option.id' :value='option.id'>
                {{ option.name }}
            </option>
          </select>
          </div>
      </div>
      </div>
        <div v-if="Documents.length <= 0">
          <div class="d-flex flex-wrap align-items-center justify-content-between gap-2" style="height: 50px; background-color: #E9ECEF !important;">
          <div><span class="text-xl text-900 font-docs text-bold ml-3" style="font-size: 16px"><strong>Documentos</strong></span></div>
        </div>
        <div class="d-flex flex-wrap align-items-center justify-content-center gap-2" :class="{'boxGray': loading}" style="height: 50px;">
          <div v-if="loading"><span class="text-xl text-900 font-docs text-bold" style="font-size: 8px;"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></span></div>
          <div v-if="!loading"><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px"><strong>Nenhum documento enviado!</strong></span></div>
        </div>
        </div>
        <div v-if="Documents.length >= 1" class="mt-4">
        <DataTable :value='Documents' :responsive="true" :rows='10' :loading='loading'>
            <template #header>
                  <div class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                   <div><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px;">Documentos</span></div>
                  </div>
              </template>
          <Column class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='width: 5rem;font-size: 16px !important;'>
            <template #body='slotProps'>
              <i v-if="!slotProps.data.favorited" class="fa fa-star-o" aria-hidden="true" style="cursor: pointer" @click="star(slotProps.data,1)"></i>
              <i v-if="slotProps.data.favorited" class="fa fa-star Color-Docs" aria-hidden="true"  style="cursor: pointer;" @click="star(slotProps.data,2)"></i>
            </template>
          </Column>
          <Column field='protocol' header='Protocolo' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <span v-if="!this.Store.mobile" class="font-docs font-weight-bold"> {{ slotProps.data.protocol }} </span>
              <span v-if="this.Store.mobile" class="font-docs font-weight-bold" style="text-align:end"> {{ slotProps.data.protocol }} </span>
            </template>
          </Column>
          <Column field='subject' header='Documento' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <div :class="{'text-end': this.Store.mobile}">
                <div><span class="font-docs font-weight-bold">{{ slotProps.data.subject }} - Despacho {{ slotProps.data.dispatch }}</span></div>
                <div class="d-flex align-items-center" :class="{'justify-content-end': this.Store.mobile}">
                  <div v-for="DepsDocument in Store.departamentsAll" :key="DepsDocument">
                    <div v-if="DepsDocument.id === slotProps.data.author.department_id">
                      <div
                          v-tooltip.top="DepsDocument.title"
                          class="d-flex align-items-center justify-content-center"
                          :style="`background-color:${DepsDocument.assets}; width:40px; height: 40px; border-radius: 50%;`">
                        <span class="font-docs text-white" style="font-size:12px"><b>{{ DepsDocument.acronym }}</b></span>
                      </div>
                    </div>
                  </div>
                <div v-if="slotProps.data.to_users.length && slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <img v-for="(tooltips, index) in slotProps.data.to_users.slice(0, 2)"
                      :key="tooltips.user.id"
                      :src="this.Store.host + tooltips.user.path_image"
                      v-tooltip.top="tooltips.user.name"
                      :style="`height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">

                  <div v-for="(tooltipsDep, index) in slotProps.data.to_departments.slice(0, 2)"
                      :key="tooltipsDep.department.id"
                      v-tooltip.top="tooltipsDep.department.title"
                      class="d-flex align-items-center justify-content-center"
                      :style="`background-color:${tooltipsDep.department.assets}; width:40px; height: 40px; border-radius: 50%; position: relative; right: ${(index + 2) * 20}px;`">
                    <span class="font-docs text-white" style="font-size:12px"><b>{{ tooltipsDep.department.acronym }}</b></span>
                  </div>

                  <div v-if="slotProps.data.to_users.length + slotProps.data.to_departments.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 80px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_users.length + slotProps.data.to_departments.length - 2 }}</b></span>
                  </div>
                </div>
              </div>

              <div v-if="slotProps.data.to_users.length && !slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <img v-for="(tooltips, index) in slotProps.data.to_users.slice(0, 3)"
                      :key="tooltips.user.id"
                      :src="this.Store.host + tooltips.user.path_image"
                      v-tooltip.top="tooltips.user.name"
                      :style="`height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">

                  <div v-if="slotProps.data.to_users.length > 3"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 60px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_users.length - 3 }}</b></span>
                  </div>
                </div>
              </div>

              <div v-if="!slotProps.data.to_users.length && slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <div v-for="(tooltipsDep, index) in slotProps.data.to_departments.slice(0, 2)"
                      :key="tooltipsDep.department.id"
                      v-tooltip.top="tooltipsDep.department.title"
                      class="d-flex align-items-center justify-content-center"
                      :style="`background-color:${tooltipsDep.department.assets}; width:40px; height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">
                    <span class="font-docs text-white" style="font-size:12px"><b>{{ tooltipsDep.department.acronym }}</b></span>
                  </div>

                  <div v-if="slotProps.data.to_departments.length > 3"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 40px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_departments.length - 3 }}</b></span>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </template>
          </Column>
          <Column field='created_at' header='Data' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <span class="font-docs font-weight-bold">{{ formatDate(slotProps.data.created_at) }}</span>
            </template>
          </Column>
          <Column field='author' header='Remetente' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
          <template #body='slotProps'>
            <div class="d-grid" style="font-size: 16px !important;">
              <span class="font-docs font-weight-bold">{{ slotProps.data.author.user.name }}</span>
              <small class="font-docs font-weight-bold">{{ slotProps.data.author.user.role }}</small>
            </div>
          </template>
          </Column>
          <Column header='Situação' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='width: 15rem;font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body="slotProps">
              <div v-if="slotProps.data.signers.some(signer => signer.user.id === this.Store.user.id)">
                <div v-for="penSigner in slotProps.data.signers" :key="penSigner">
                  <div v-if="penSigner.user.id === this.Store.user.id">
                    <div v-if="penSigner.clicksign_status === '0'" class="d-flex align-items-center justify-content-center mr-3" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                      <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                    </div>
                    <div v-if="penSigner.clicksign_status === '1'" class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                      <span class="font-docs font-weight-bold">Em movimentação</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!slotProps.data.signers.some(signer => signer.user.id === this.Store.user.id) && slotProps.data.logs.some(logs => logs.type === '4')">
                <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #F8F9FA;color: #495057;border-radius: 10px;">
                  <span class="font-docs font-weight-bold">Arquivado</span>
                </div>
              </div>
              <div v-if="!slotProps.data.signers.some(signer => signer.user.id === this.Store.user.id) && !slotProps.data.logs.some(logs => logs.type === '4')">
                <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                  <span class="font-docs font-weight-bold">Em movimentação</span>
                </div>
              </div>
            </template>
          </Column>
          <Column class='text-sm text-wrap font-weight-bold' headerClass='font-docs-recived-header' headerStyle='width: 5rem;'>
            <template #body="slotProps">
              <div v-if="slotProps.data.signers.some(signer => signer.user.id === this.Store.user.id)">
                <div v-for="penSigner in slotProps.data.signers" :key="penSigner">
                  <div v-if="penSigner.user.id === this.Store.user.id">
                    <font-awesome-icon v-if="penSigner.clicksign_status === '0'" :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#Signer"/>
                    <font-awesome-icon v-if="penSigner.clicksign_status === '1'" :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                  </div>
                </div>
              </div>
              <div v-if="!slotProps.data.signers.some(signer => signer.user.id === this.Store.user.id)">
                <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
              </div>
            </template>
          </Column>
          <Column class='text-sm text-wrap font-weight-bold' headerClass='font-docs-recived-header' headerStyle='width: 5rem;'>
            <template #body="slotProps">
              <router-link  :to='{ name: "EnviadosView", params: { id: slotProps.data.protocol }}'>
              <font-awesome-icon :icon="['fas', 'chevron-right']" style="font-size: 18px !important; cursor:pointer"  @click="this.Store.viewDispatch = slotProps.data.dispatch"/>
              </router-link>
            </template>
          </Column>
        </DataTable>
        <Paginator class="d-flex justify-content-center" :rows="optionspag.per_page" :totalRecords="optionspag.total" :first="optionspag.from" :pageLinkSize="3" @page="onPageChange"></Paginator>
      </div>

      <button ref="wrongPassword" class="d-none" data-toggle="modal" data-target="#Signer"></button>
      <div class="modal fade" id="Signer" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar documento</b></h4>
              <button type="button" id="closeModalSends" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="Signer()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="Loading" tabindex="-1" role="dialog" aria-labelledby="Loading" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" ref="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <span class="font-docs text-white d-flex justify-content-center">Assinando documento, Por favor, aguarde...</span>
            <div class="progress" style="height: 30px;">
              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
              animation: progress-bar-stripes 1s linear infinite !important;
              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
              background-size: 0.5rem 1rem;
              transition: width .6s ease;
              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  </template>
