<script>
import Dashboard_adm from '../../components/documents/Dashboard_adm.vue';
import Dashboard_default from '../../components/documents/Dashboard_default.vue';
import DocumentsPrivate from '../../components/documents/DocumentsPrivate.vue';
import { useMainStore } from '../../stores/index';
export default {
  name: 'DashboardDefault',
  components: { Dashboard_adm, Dashboard_default, DocumentsPrivate },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },

  data () {
    return {
      admin: false,
      gestor: false,
      prefeitura: false,
      servidor: false,
      external: false,
      componenteHeight: '93vh'
    };
  },

  created () {
    const OnSystem = setInterval(() => {
      if (this.Store.user.id !== undefined) {
        clearInterval(OnSystem);
        this.Store.user.abilities.forEach(abilitie => {
          if (abilitie.id === 1) {
            this.admin = true;
          }
          if (abilitie.id === 2) {
            this.gestor = true;
          }
          if (abilitie.id === 3) {
            this.prefeitura = true;
          }
          if (abilitie.id === 4) {
            this.servidor = true;
          }
          if (abilitie.id === 5) {
            this.external = true;
          }
        });
        this.Loading = false;
      }
    }, 500);
  },

  mounted () {
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('homepage');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    }
  }
};
</script>
<style>
</style>
<template>
  <div class='container-fluid'>
    <div>
      <div>
        <div>
          <div>
            <div id="homepage" :style="{ minHeight: componenteHeight }">
            <Dashboard_default v-if="gestor"/>
            <Dashboard_adm v-if="admin"/>
            <Dashboard_default v-if="prefeitura"/>
            <Dashboard_default v-if="servidor"/>
            <Dashboard_default v-if="external"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
