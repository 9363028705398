<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  name: 'DashboardDefault',
  components: { LineChart },
  setup () {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
          title: {
            display: true
          },
          labels: {
            usePointStyle: true
          }
        }
      }
    };
    const StoreAll = useMainStore();
    StoreAll.Token();
    return { Store: StoreAll, options };
  },
  data () {
    return {
      loading: true,
      page: 1,
      optionspag: [],
      componenteHeight: '93vh',
      DocumentsSigner: [],
      NotViewed: 0,
      DocSend: 0,
      DocRecep: 0,
      DocSign: 0,
      DocLessSign: 0,
      date_start: '',
      date_end: '',
      showPassword: false,
      testData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Assinados',
            backgroundColor: '#479F76',
            borderColor: '#479F76',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [10, 20, 30, 40, 50, 60, 70]
          },
          {
            label: 'Enviados',
            backgroundColor: '#F1860B',
            borderColor: '#F1860B',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [100, 0, 20, 45, 28, 10, 87]
          },
          {
            label: 'Recebidos',
            backgroundColor: '#0D6EFD',
            borderColor: '#0D6EFD',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      DocumentSignerId: 0,
      progress: 0,
      SignerSucess: false,
      NameDocSigner: [],
      wrongPasswords: false,
      password: ''
    };
  },
  watch: {
    date_start: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start !== '' && this.date_end === '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start === '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
    },
    date_end: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start !== '' && this.date_end === '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start === '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
    }
  },
  mounted () {
    this.checkComponentHeight();
    this.GetNumbers();
    this.Store.DepartamentosAll();
    this.GetDocs();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Dashboard');
      const alturaViewport = window.innerHeight;
      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh'; // Adaptar ao tamanho adicional
      }
    },

    GetDocs () {
      this.loading = true;
      AxiosAPI
        .get('api/documents/signature-requirement?page=' + this.page + '&status=pending', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          this.DocumentsSigner = response.data.documents.data;
          this.optionspag = response.data.documents;
          this.loading = false;
        });
    },

    onPageChange (event) {
      this.page = event.page + 1;
      this.GetDocs();
    },

    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MMMM/YYYY');
    },

    GetNumbers () {
      this.loading = true;
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      const todayFormatted = today.toLocaleDateString('en-US', options);
      const thirtyDaysAgoFormatted = thirtyDaysAgo.toLocaleDateString('en-US', options);

      const yearStart = thirtyDaysAgo.getFullYear();
      const monthStart = String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0');
      const dayStart = String(thirtyDaysAgo.getDate()).padStart(2, '0');

      const yearend = today.getFullYear();
      const monthend = String(today.getMonth() + 1).padStart(2, '0');
      const dayend = String(today.getDate()).padStart(2, '0');

      this.date_start = `${yearStart}-${monthStart}-${dayStart}`;
      this.date_end = `${yearend}-${monthend}-${dayend}`;
      AxiosAPI
        .get('/api/documents/activity',
          {
            params: {
              date_start: thirtyDaysAgoFormatted,
              date_end: todayFormatted
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
          this.NotViewed = response.data.documents.not_viewed;
          this.DocSend = response.data.documents.sents;
          this.DocRecep = response.data.documents.receiveds;
          this.DocSign = response.data.documents.signeds;
          this.DocLessSign = response.data.documents.to_sign;
          this.loading = false;
        });
    },

    FilterActivity () {
      const receveds = [];
      const signers = [];
      const sents = [];
      const months = [];
      const monthsNumb = [];
      const currentDate = new Date(this.date_start);
      const endDate = new Date(this.date_end);
      while (currentDate <= endDate) {
        const month = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthNumb = currentDate.toLocaleString('default', { month: 'numeric', year: 'numeric' });
        months.push(month);
        monthsNumb.push(monthNumb);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      AxiosAPI
        .get('/api/documents/activity/by-date',
          {
            params: {
              date_start: this.date_start,
              date_end: this.date_end
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
          receveds.push(...Object.values(response.data.documents.receiveds));
          signers.push(...Object.values(response.data.documents.signeds));
          sents.push(...Object.values(response.data.documents.sents));
          this.testData = {
            labels: months,
            datasets: [
              {
                label: 'Assinados',
                color: '#479F76',
                backgroundColor: '#479F76',
                data: signers
              },
              {
                label: 'Enviados',
                backgroundColor: '#F1860B',
                data: sents
              },
              {
                label: 'Recebidos',
                backgroundColor: '#0D6EFD',
                data: receveds
              }
            ]
          };
        });
    },

    async Signer () {
      this.wrongPasswords = false;
      document.getElementById('closeModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.NameDocSigner = resp.data.document;
            this.SignerSucess = true;
            this.GetDocs();
            this.wrongPasswords = false;
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.wrongPassword.click();
          clearInterval(interval);
        });
    },
    async SignerAttach () {
      document.getElementById('SignerAttachcloseModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign-leader`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerAttachSucess = true;
            this.GetDocs();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.AttachwrongPassword.click();
          clearInterval(interval);
        });
    },
    async SignerWithAttach () {
      document.getElementById('SignerWithAttachcloseModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.NameDocSigner = resp.data.document;
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.WithAttachwrongPassword.click();
          clearInterval(interval);
        });

      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign-leader`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerWithAttachSucess = true;
            this.GetDocs();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        });
    },

    async ViewDoc (value) {
      await AxiosAPI
        .post('/api/documents/' + value.id + '/view', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then(() => {
          this.GetDocs();
        });
    },

    SignerStart () {
      this.password = '';
      this.wrongPasswords = false;
    }
  }
};
</script>
<style>
.boxSDashboard {
  width: 92px;
  height: 61px;
  border-radius: 8px;
  background-color: #E7F1FF;
  padding: 16px;
}
.boxSDashboard-pendente {
  width: 92px;
  height: 61px;
  border-radius: 8px;
  background-color: #F1860B;
  padding: 16px;
}
</style>
<template>
  <div class='py-4 container-fluid' id="Dashboard">
    <div class="mb-4">
    <h5 class="font-docs" style="color:black"><strong>Acompanhe suas métricas</strong></h5>
    </div>
    <div v-if="SignerSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} assinado!  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerSucess = false" />
          </div>
        </div>
    <button type="button" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>
    <div v-if="!this.Store.mobile" class="d-flex w-100">
      <div class="mb-2 w-50">
        <div class="mb-2">
          <label class="label-docs"><b>Périodo</b></label>
            <div class="d-flex">
              <input type="date" class="form-control" v-model="date_start" style="width: 49%;border-right: 0;border-bottom-right-radius: 0;border-top-right-radius: 0;"/>
              <input type="date" class="form-control" v-model="date_end"  style="width: 49%;border-left: 0;border-bottom-left-radius: 0;border-top-left-radius: 0;"/>
            </div>
          </div>
      <div>
        <LineChart
          :chartData="testData"
          :options="options"
        />
      </div>
      </div>
      <div class="w-50">
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocRecep }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos recebidos</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocSend }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos enviados</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocSign }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos assinados</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard-pendente text-center">
            <h4 class="text-white">{{ DocLessSign }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos pendetes de assinatura</strong></span>
        </div>
        </div>
      </div>

      <div v-if="this.Store.mobile">
        <div class="mb-2 w-100">
        <div class="mb-2">
          <label class="label-docs"><b>Périodo</b></label>
            <div class="d-flex">
              <input type="date" class="form-control" v-model="date_start" style="width: 49%;border-right: 0;border-bottom-right-radius: 0;border-top-right-radius: 0;"/>
              <input type="date" class="form-control" v-model="date_end"  style="width: 49%;border-left: 0;border-bottom-left-radius: 0;border-top-left-radius: 0;"/>
            </div>
          </div>
      <div>
        <LineChart
          :chartData="testData"
          :options="options"
        />
      </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocRecep }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos recebidos</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocSend }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos enviados</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard text-center">
            <h4 class="Color-Docs">{{ DocSign }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos assinados</strong></span>
        </div>
        <div class="d-flex align-items-center mb-2 w-100 background-pags py-3 px-3">
          <div class="mr-2 boxSDashboard-pendente text-center">
            <h4 class="text-white">{{ DocLessSign }}</h4>
            </div>
          <span class="font-docs" style="color:black"><strong>Documentos pendetes de assinatura</strong></span>
        </div>
        </div>
      </div>

    <h5 class="font-docs mt-5" style="color:black"><strong>Pendentes para você assinar</strong></h5>
    <hr>
    <div v-if="DocumentsSigner.length <= 0" class="mt-4">
      <div class="d-flex flex-wrap align-items-center justify-content-between gap-2" style="height: 50px; background-color: #E9ECEF !important;">
          <div><span class="text-xl text-900 font-docs text-bold ml-3" style="font-size: 16px"><strong>Pendetes</strong></span></div>
        </div>
        <div class="d-flex flex-wrap align-items-center justify-content-center gap-2" :class="{'boxGray': loading}" style="height: 50px;">
          <div v-if="loading"><span class="text-xl text-900 font-docs text-bold" style="font-size: 8px;"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></span></div>
          <div v-if="!loading"><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px"><strong>Nenhum documento pendente!</strong></span></div>
        </div>
    </div>
    <div v-if="DocumentsSigner.length >= 1">
      <div class="d-flex flex-wrap align-items-center justify-content-between gap-2" style="height: 50px; background-color: #E9ECEF !important;">
          <div><span class="text-xl text-900 font-docs text-bold ml-3" style="font-size: 16px"><strong>Pendetes</strong></span></div>
        </div>
        <div v-if="!loading">
          <DataTable :value='DocumentsSigner' :responsive="true" :rows='10'>
          <Column class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 5rem;font-size: 16px !important;'>
          <template #body='slotProps'>
            <i v-if="!slotProps.data.favorited" class="fa fa-star-o" aria-hidden="true" style="cursor: pointer" @click="star(slotProps.data,1)"></i>
            <i v-if="slotProps.data.favorited" class="fa fa-star Color-Docs" aria-hidden="true"  style="cursor: pointer;" @click="star(slotProps.data,2)"></i>
          </template>
          </Column>
          <Column header='Protocolo' class='text-sm fw-bolder text-wrap font-docs-recived-header' headerClass='font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <span class="font-docs font-weight-bold"> {{ slotProps.data.protocol }} </span>
            </template>
          </Column>
          <Column field='subject' header='Documento' class='text-sm text-wrap font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <div :class="{'text-end': this.Store.mobile}">
                <div><span class="font-docs font-weight-bold">{{ slotProps.data.subject }} - Despacho {{ slotProps.data.dispatch }}</span></div>
                <div class="d-flex align-items-center" :class="{'justify-content-end': this.Store.mobile}">
                  <div v-for="DepsDocument in Store.departamentsAll" :key="DepsDocument">
                    <div v-if="DepsDocument.id === slotProps.data.author.department_id">
                      <div
                          v-tooltip.top="DepsDocument.title"
                          class="d-flex align-items-center justify-content-center"
                          :style="`background-color:${DepsDocument.assets}; width:40px; height: 40px; border-radius: 50%;`">
                        <span class="font-docs text-white" style="font-size:12px"><b>{{ DepsDocument.acronym }}</b></span>
                      </div>
                    </div>
                  </div>
                <div v-if="slotProps.data.to_users.length && slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <img v-for="(tooltips, index) in slotProps.data.to_users.slice(0, 2)"
                      :key="tooltips.user.id"
                      :src="this.Store.host + tooltips.user.path_image"
                      v-tooltip.top="tooltips.user.name"
                      :style="`height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">

                  <div v-for="(tooltipsDep, index) in slotProps.data.to_departments.slice(0, 2)"
                      :key="tooltipsDep.department.id"
                      v-tooltip.top="tooltipsDep.department.title"
                      class="d-flex align-items-center justify-content-center"
                      :style="`background-color:${tooltipsDep.department.assets}; width:40px; height: 40px; border-radius: 50%; position: relative; right: ${(index + 2) * 20}px;`">
                    <span class="font-docs text-white" style="font-size:12px"><b>{{ tooltipsDep.department.acronym }}</b></span>
                  </div>

                  <div v-if="slotProps.data.to_users.length + slotProps.data.to_departments.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 80px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_users.length + slotProps.data.to_departments.length - 2 }}</b></span>
                  </div>
                </div>
              </div>

              <div v-if="slotProps.data.to_users.length && !slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <img v-for="(tooltips, index) in slotProps.data.to_users.slice(0, 3)"
                      :key="tooltips.user.id"
                      :src="this.Store.host + tooltips.user.path_image"
                      v-tooltip.top="tooltips.user.name"
                      :style="`height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">

                  <div v-if="slotProps.data.to_users.length > 3"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 60px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_users.length - 3 }}</b></span>
                  </div>
                </div>
              </div>

              <div v-if="!slotProps.data.to_users.length && slotProps.data.to_departments.length" class="d-flex align-items-center">
                <i class="fa fa-long-arrow-right ml-2 mr-2" aria-hidden="true"></i>
                <div class="d-flex">
                  <div v-for="(tooltipsDep, index) in slotProps.data.to_departments.slice(0, 2)"
                      :key="tooltipsDep.department.id"
                      v-tooltip.top="tooltipsDep.department.title"
                      class="d-flex align-items-center justify-content-center"
                      :style="`background-color:${tooltipsDep.department.assets}; width:40px; height: 40px; border-radius: 50%; position: relative; right: ${index * 20}px;`">
                    <span class="font-docs text-white" style="font-size:12px"><b>{{ tooltipsDep.department.acronym }}</b></span>
                  </div>

                  <div v-if="slotProps.data.to_departments.length > 3"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 40px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ slotProps.data.to_departments.length - 3 }}</b></span>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </template>
          </Column>
          <Column field='created_at' header='Data' class='text-sm text-wrap font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body='slotProps'>
              <span class="font-docs font-weight-bold">{{ formatDate(slotProps.data.created_at) }}</span>
            </template>
          </Column>
          <Column field='author' header='Remetente' class='text-sm text-wrap font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
          <template #body='slotProps'>
            <div class="d-grid" style="font-size: 16px !important;">
              <span class="font-docs font-weight-bold">{{ slotProps.data.author.user.name }}</span>
              <small class="font-docs font-weight-bold">{{ slotProps.data.author.user.role }}</small>
            </div>
          </template>
          </Column>
          <Column header='Situação' class='text-sm text-wrap font-docs-recived-header' headerStyle='width: 15rem;font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body="slotProps">
              <div v-if="slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
                <div v-for="signer in slotProps.data.signers" :key="signer">
                  <div v-if="signer.clicksign_status === '0'">
                      <div class="d-flex align-items-center justify-content-center" style="width: 195px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                      <span class="font-docs font-weight-bold">Aguardando assinatura(as)</span>
                    </div>
                  </div>
                  <div v-if="signer.clicksign_status !== '0'">
                      <div class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Assinado(os)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="slotProps.data.signers.length >= 1 && !slotProps.data.attachments_sign">
                <div v-for="signer in slotProps.data.signers" :key="signer">
                  <div v-if="signer.clicksign_status === '0'">
                      <div class="d-flex align-items-center justify-content-center" style="width: 185px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                      <span class="font-docs font-weight-bold">Aguardando assinatura</span>
                    </div>
                  </div>
                  <div v-if="signer.clicksign_status !== '0'">
                      <div class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Assinado</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
                <div v-if="slotProps.data.attachment_sign_status === 0">
                    <div class="d-flex align-items-center justify-content-center" style="width: 185px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                    <span class="font-docs font-weight-bold">Assinatura anexo(os)</span>
                  </div>
                </div>
                <div v-if="slotProps.data.attachment_sign_status === 1">
                    <div class="d-flex align-items-center justify-content-center" style="width: 185px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;">
                      <span class="font-docs font-weight-bold">Assinado anexo(os)</span>
                  </div>
                </div>
              </div>
          </template>
          </Column>
          <Column header='Assinatura obrigatória' class='text-sm text-wrap font-docs-recived-header' headerStyle='font-size: 16px !important;' style="font-size: 16px !important;">
            <template #body="slotProps">
            <div v-if="slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
              <div v-for="signer in slotProps.data.signers" :key="signer">
                <span v-if="signer.required === true">Sim</span>
                <span v-if="signer.required === false">Não</span>
              </div>
            </div>
            <div v-if="slotProps.data.signers.length >= 1 && !slotProps.data.attachments_sign">
              <div v-for="signer in slotProps.data.signers" :key="signer">
                <span v-if="signer.required === true">Sim</span>
                <span v-if="signer.required === false">Não</span>
              </div>
            </div>
            <div v-if="!slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
              <div>
                <span>Não</span>
              </div>
            </div>
          </template>
          </Column>
          <Column class='text-sm text-wrap font-weight-bold' headerStyle='width: 5rem;'>
            <!-- <template #body="slotProps">
            <div v-for="signer in slotProps.data.signers" :key="signer">
                <div v-if="signer.clicksign_status === '0'">
                    <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#Signer"/>
                </div>
                <div v-if="signer.clicksign_status !== '0'">
                    <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                </div>
            </div>
          </template> -->
          <template #body="slotProps">
              <div v-if="slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
                <div v-for="signer in slotProps.data.signers" :key="signer">
                    <div v-if="signer.clicksign_status === '0' && slotProps.data.attachment_sign_status === 0">
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#SignerWithAttach"/>
                    </div>
                    <div v-if="signer.clicksign_status === '0' && slotProps.data.attachment_sign_status === 1">
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#Signer"/>
                    </div>
                    <div v-if="signer.clicksign_status !== '0' && slotProps.data.attachment_sign_status === 0">
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#SignerAttach"/>
                    </div>
                    <div v-if="signer.clicksign_status !== '0' && slotProps.data.attachment_sign_status === 1">
                      <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                    </div>
                </div>
              </div>
              <div v-if="!slotProps.data.signers.length >= 1 && slotProps.data.attachments_sign">
                    <div v-if="slotProps.data.attachment_sign_status === 0">
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#SignerAttach"/>
                    </div>
                    <div v-if="slotProps.data.attachment_sign_status === 1">
                      <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                    </div>
              </div>
              <div v-if="slotProps.data.signers.length >= 1 && !slotProps.data.attachments_sign">
                <div v-for="signer in slotProps.data.signers" :key="signer">
                    <div v-if="signer.clicksign_status === '0'">
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentSignerId = slotProps.data.id; SignerStart()" data-toggle="modal" data-target="#Signer"/>
                    </div>
                    <div v-if="signer.clicksign_status !== '0'">
                      <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                    </div>
                </div>
              </div>
          </template>
          </Column>
          <Column class='text-sm text-wrap font-weight-bold' headerStyle='width: 5rem;'>
            <template #body="slotProps">
              <router-link :to='{ name: "Assinaturasview", params: { id: slotProps.data.protocol }}'>
                <font-awesome-icon :icon="['fas', 'chevron-right']" style="font-size: 18px !important; cursor:pointer" @click="ViewDoc(slotProps.data); this.Store.viewDispatch = slotProps.data.dispatch"/>
              </router-link>
            </template>
          </Column>
        </DataTable>
        <Paginator class="d-flex justify-content-center" :rows="optionspag.per_page" :totalRecords="optionspag.total" :first="optionspag.from" :pageLinkSize="3" @page="onPageChange"></Paginator>
      </div>
    </div>

    <button ref="wrongPassword" class="d-none" data-toggle="modal" data-target="#Signer"></button>
      <div class="modal fade" id="Signer" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar documento</b></h4>
              <button type="button" id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="Signer()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <button ref="WithAttachwrongPassword" class="d-none" data-toggle="modal" data-target="#SignerWithAttach"></button>
      <div class="modal fade" id="SignerWithAttach" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar documento e anexo(os)</b></h4>
              <button type="button" id="SignerWithAttachcloseModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="SignerWithAttach()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <button ref="AttachwrongPassword" class="d-none" data-toggle="modal" data-target="#SignerAttach"></button>
      <div class="modal fade" id="SignerAttach" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar anexo(os)</b></h4>
              <button type="button" id="SignerAttachcloseModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="SignerAttach()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="Loading" tabindex="-1" role="dialog" aria-labelledby="Loading" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" ref="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <span class="font-docs text-white d-flex justify-content-center">Assinando documento, Por favor, aguarde...</span>
            <div class="progress" style="height: 30px;">
              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
              animation: progress-bar-stripes 1s linear infinite !important;
              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
              background-size: 0.5rem 1rem;
              transition: width .6s ease;
              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
