<script>
import Sidenav from '../src/components/Sidenav/index.vue';
import Navbar from '../src/components/Navbars/Navbar.vue';
import { useMainStore, useEventStore } from './stores/index';
import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    Sidenav,
    Navbar
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      sidebarHeight: 0
    };
  },
  watch: {
    'event.SignerConfirm': function (newValue, oldPath) {
      if (newValue === true) {
        this.event.SignerConfirm = null;
        this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Assinatura confirmada!', group: 'tl', life: 8000 });
        this.$router.push('/signer');
        setTimeout(() => {
          this.Notifys();
        }, 2000);
      }
    },

    'event.SendDocument': function (newValue, oldPath) {
      if (newValue === true) {
        this.event.SendDocument = null;
        this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Documento encaminhado com sucesso!', group: 'tl', life: 8000 });
      }
    },

    '$root.$data.bodyWidth': function (newPath, oldPath) {
      if (newPath < 625) {
        this.Store.mobile = true;
      }
      if (newPath > 625) {
        this.Store.mobile = false;
      }
    }
  },

  computed: {
    ...mapState([
      'layout',
      'showSidenav',
      'showNavbar',
      'showFooter',
      'showConfig',
      'hideConfigButton'
    ])
  },

  mounted () {
    const checkRoute = () => {
      const routeName = this.$route.name;
      if (routeName != null && routeName !== 'Login' && routeName !== 'Esqueci minha senha' && this.$route.name !== 'CadastroExterno') {
        clearInterval(intervalId);
        this.Get_departaments();
        this.Get_user();
      }
    };

    const intervalId = setInterval(checkRoute, 1000);
    const observer = new ResizeObserver(() => {
      this.$root.$data.bodyHeight = document.body.clientHeight;
      this.$root.$data.bodyWidth = document.body.clientWidth;
    });

    observer.observe(document.body);

    if (window.innerWidth < 625) {
      this.Store.mobile = true;
      this.Store.isPinned = false;
    }
    if (window.innerWidth > 625) {
      this.Store.mobile = false;
      this.Store.isPinned = true;
    }
  },

  methods: {
    Get_departaments () {
      this.Store.Get_departaments();
      this.Store.Get_abilities();
    },

    async Notifys () {
      /* await this.Store.Notify(); */
      await this.Store.Notify_received();
    },

    async Get_user () {
      await this.Store.Get_user();
      this.Store.Get_users();
      this.Store.GetNumbers();
      this.Notifys();
    },

    showCenter () {
      this.$toast.add({ severity: 'info', summary: 'Votação nominal', detail: 'O documento ' + this.document_name + ' foi votado nominalmente e está ' + this.Nominal_vote_result + '', group: 'tl', life: 8000 });
    },

    Sucess_subscribe_tribune () {
      this.$toast.add({ severity: 'success', summary: 'Inscrição na tribuna', detail: 'Você foi inscrito na tribuna com sucesso', group: 'tl', life: 8000 });
    },
    Not_subscribe_tribune () {
      this.$toast.add({ severity: 'success', summary: 'Inscrição na tribuna', detail: 'Você optou por não se inscrever', group: 'tl', life: 8000 });
    },

    showSuccess_voteF () {
      this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto a favor foi computado', life: 3000 });
    },
    showSuccess_voteC () {
      this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto contra foi computado', life: 3000 });
    },
    showSuccess_voteA () {
      this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto como abstenção foi computado', life: 3000 });
    }

  // Finaliza as funções da tribuna ---
  }
};
</script>
<style scoped>
 @import './assets/css/main.css';
.NavResponse{
  width: 100% !important;
}
@media (max-width: 400px) {
  .NavResponse {
    width: 110% !important;
  }
}

@media (max-width: 300px) {
  .NavResponse {
    width: 145% !important;
  }
}
</style>
<template>
  <div>
    <div
      v-show="layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>

    <div class="d-flex" v-if="this.$route.name == 'NotFound' || this.$route.name == 'NotFoundCompany' || this.$route.name == 'TokenInvalid'">
        <router-view />
    </div>

    <div v-if="this.$route.name == 'Login' || this.$route.name == 'Esqueci minha senha' || this.$route.name == 'CadastroExterno'">
      <router-view />
    </div>

    <navbar v-if="showNavbar && this.$route.name != 'Login' && this.$route.name != 'Esqueci minha senha' && this.$route.name != 'CadastroExterno' && this.$route.name != 'NotFound' && this.$route.name != 'NotFoundCompany' && this.$route.name != 'TokenInvalid'"
    :class="{'fixed-mobile': this.Store.mobile && this.Store.isPinned}"
    style="padding-top: 0 !important; padding-bottom: 0 !important"/>
      <div class="d-flex" v-if="this.$route.name != 'Login' && this.$route.name != 'Esqueci minha senha' && this.$route.name != 'CadastroExterno' && this.$route.name != 'NotFound' && this.$route.name != 'NotFoundCompany' && this.$route.name != 'TokenInvalid'">
        <sidenav v-if="showSidenav && this.Store.role !== 1 && this.$route.name != 'NotFound' && this.$route.name != 'NotFoundCompany' && this.$route.name != 'TokenInvalid'" />
        <router-view style="background-color: #F8F9FA;"/>
      </div>
    <Toast />
    <Toast position="center" group="tl" />
  </div>
</template>
