<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import moment from 'moment';
import Breadcrumb from '../../components/documents/patchName.vue';
export default {
  name: 'RecivedDocs',
  components: { Breadcrumb },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      componenteHeight: '93vh',
      documents: [],
      Types: [],
      Loading: true,
      RemoveDraftSuccess: false
    };
  },
  watch: {
  },
  mounted () {
    this.GetTypes();
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Rascunhos');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MMMM/YYYY');
    },

    async GetTypes () {
      const typesAPI = await this.Store.GetTypes();
      this.Types = typesAPI;
      this.getDrafts();
    },

    getDrafts () {
      AxiosAPI
        .get('api/drafts', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          const documents = [];
          response.data.drafts.forEach(element => {
            element.body = JSON.parse(element.content);
            documents.push(element);
          });
          this.documents = documents;
          this.Loading = false;
        });
    },

    removeDraft (value) {
      this.Loading = true;
      AxiosAPI
        .delete('api/drafts/' + value.id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          console.log(response);
          this.RemoveDraftSuccess = true;
          this.getDrafts();
        });
    },

    CreatDocument (value) {
      this.Store.is_new_document = false;
      this.Store.draftID = value.id;
      this.Store.progress = 2;
      this.Store.documentData = { typeDoc: value.document_type_id, attachments: [], signerAttach: null, subject: value.subject, body: value.body[0].body, status_sign: 0, user_id: value.user_id };
    }

  }
};
</script>
<style>
.box-model-rascunho{
  width: 247px !important;
  height: 138px;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
}
.font-rascunho{
  font-size: 14px;
}
.box-blur-rascunho{
  width: 100% !important;
}
@media screen and (max-width: 1450px) {
.box-model-rascunho{
  width: 220px !important;
}
}

@media screen and (max-width: 1380px) {

.box-model-rascunho{
  width: 220px !important;
}
}

@media screen and (max-width: 1200px) {
.box-model-rascunho{
  width: 220px !important;
}
}

@media screen and (max-width: 1180px) {
  .box-model-rascunho{
  width: 220px !important;
}
}

@media screen and (max-width: 1050px) {
  .box-model-rascunho{
  width: 220px !important;
}
}
@media screen and (max-width: 992px) {
  .box-model-rascunho{
  width: 150px !important;
  height:158px !important;
}
}
@media screen and (max-width: 850px) {
  .box-model-rascunho{
  width: 150px !important;
}
}
@media screen and (max-width: 550px) {
  .box-model-rascunho{
  width: 120px !important;
}
}
</style>
<template>
    <div class='py-4 container-fluid' id="Rascunhos" :style="{ minHeight: componenteHeight }">
      <Breadcrumb />
      <h4 style="color:black">Rascunhos</h4>
      <div v-if="this.RemoveDraftSuccess" class="d-flex justify-content-between align-items-center mb-4" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> Rascunho removido.  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="this.RemoveDraftSuccess = false" />
          </div>
        </div>
      <div v-if="Loading" class="row d-flex justify-content-center align-items-center mt-5">
        <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
        <span class="d-flex justify-content-center align-items-center font-docs">carregando...</span>
      </div>
      <div v-if="!Loading && !this.Store.mobile" class="container mt-4">
      <div class="row">
        <div v-for="(draftsNow, index) in documents" :key="index" class="col-6 col-sm-4 box-model-rascunho ml-5 mb-3" style="background-color: #E9ECEF; border: 2px solid #F1860B; border-style: solid;">
        <div @click="CreatDocument(draftsNow)" >
          <router-link :to='{ name: "Criar de documento"}' exact>
          <div class="box-blur-rascunho" style="height:28px;filter: blur(5px);">
            <span>***********</span>
          </div>
          <div v-for="docType in Types" :key="docType">
            <span v-if="docType.id === draftsNow.document_type_id" class="font-rascunho font-docs mt-2"><b>{{docType.title}}</b>
            </span>
          </div>
          <div>
            <span class="font-rascunho font-docs mt-2"><b>Titulo: {{ draftsNow.subject.substring(0, 10) }} {{ draftsNow.subject.length > 10 ? '...' : '...' }}</b></span>
            </div>
            </router-link>
          </div>
          <div class="d-flex justify-content-end align-items-center">
          <button type='button' @click='removeDraft(draftsNow)'
                    style='border: none;'>
              <i class="fa fa-trash-o" aria-hidden="true" style="color: #212529"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!Loading && this.Store.mobile" class="container mt-4">
      <div class="row">
        <div v-for="(draftsNow, index) in documents" :key="index" class="col-6 col-sm-4 box-model-rascunho ml-3 mb-3" style="background-color: #E9ECEF; border: 2px solid #F1860B; border-style: solid;" @click="nextProgress(index+1)" >
          <div class="box-blur-rascunho" style="height:28px;filter: blur(5px);">
            <span>****a***</span>
          </div>
          <div v-for="docType in Types" :key="docType">
            <span v-if="docType.id === draftsNow.document_type_id" class="font-rascunho font-docs mt-2"><b>{{docType.title}}</b>
            </span>
          </div>
          <span class="font-rascunho font-docs mt-2"><b>Titulo: {{ draftsNow.subject.substring(0, 5) }} {{ draftsNow.subject.length > 5 ? '...' : '...' }}</b></span>
        </div>
      </div>
    </div>
  </div>
  </template>
